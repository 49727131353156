import React from 'react';

import SignupForm from './Signup';

const SignupPage = () => {
	return (
		<div
			className='bg-white mx-auto mt-5 px-4 py-2'
			style={{ borderRadius: '1rem', minWidth: '350px', width: '40%' }}
		>
			<SignupForm />
		</div>
	);
};

export default SignupPage;
