import React from 'react';
import { withRouter } from 'react-router-dom';

import SignupForm from 'components/forms/Signup';

const Sidebar = ({ history }) => {
	return (
		<aside className='sidebar'>
			<div className='promotion-section'>
				<h3>
					<strong>Приклучете се и добијте бесплатна книга</strong>
				</h3>
				<SignupForm history={history} />
			</div>
			<section className='promotion-section'>
				<h3>
					<strong>Изучувајте:</strong>
				</h3>
				<ul>
					<li>Емоционална Интелегенција</li>
					<li>Подготовка за студии во странство</li>
					<li>Математика</li>
					<li>ИТ</li>
					<li>Физика и астрономија</li>
					<li>Шах</li>
					<li>Хемија</li>
				</ul>
			</section>
		</aside>
	);
};

export default withRouter(Sidebar);
