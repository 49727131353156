import { db } from 'services/firebase';

export const getArticleContent = async (pageId, articleId) => {
	try {
		const docRef = await db
			.collection('content')
			.doc(pageId)
			.collection('articles')
			.doc(articleId);

		let doc;
		try {
			doc = await docRef.get({ source: 'cache' });
		} catch (error) {
			if (error.code !== 'unavailable') throw error;

			try {
				doc = await docRef.get({ source: 'server' });
			} catch (_error) {
				if (_error.code === 'unavailable') throw _error;
			}
		}

		if (!doc.exists) throw Error("Article doesn't exist!");

		return doc.data();
	} catch (error) {
		console.error(error);
	}
};

export const getPageCategories = async (pageId) => {
	try {
		const docRef = await db.collection('pages').doc(pageId);

		const doc = await docRef.get();

		if (!doc.exists) throw Error("Article doesn't exist!");

		return doc.data();
	} catch (error) {
		console.error(error);
	}
};

// getPageCategories('start_here').then((data) => console.info(data));

// export const getArticleContent = async (pageId, articleId) => {
// 	try {
// 		const docRef = await db
// 			.collection('content')
// 			.doc(pageId)
// 			.collection('articles')
// 			.doc(articleId);

// 		let doc;
// 		try {
// 			doc = await docRef.get({ source: 'cache' });
// 		} catch (error) {
// 			if (error.code !== 'unavailable') throw error;

// 			try {
// 				doc = await docRef.get({ source: 'server' });
// 			} catch (_error) {
// 				if (_error.code === 'unavailable') throw _error;
// 			}
// 		}

// 		if (!doc.exists) throw Error("Article doesn't exist!");

// 		return doc.data();
// 	} catch (error) {
// 		console.error(error);
// 	}
// };
