import React from 'react';

import CourseDropdown from 'components/layout/CourseDropdown';

const Slogan = () => {
	return (
		<div className='col1 pt-5'>
			<h1 className='slogan-part-1'>НИКОГАШ НЕ ПРЕКИНУВАЈ</h1>
			<h1 className='slogan-part-2'>ДА УЧИШ</h1>
			<div className='description py-5'>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus tempora
				in quae optio ad praesentium laborum, placeat aliquid quas esse?
			</div>
			<div className='buttons'>
				<button className='btn btn-warning'>
					Започни со бесплатен тренинг
				</button>
				<span className='course-dropdown'>
					<button
						className='btn btn-primary dropdown-toggle'
						type='button'
						id='dropdownMenuButton'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						<i className='fas fa-th'></i>&nbsp;Истражи ги тренинзите
					</button>
					<CourseDropdown />
				</span>
			</div>
		</div>
	);
};

export default Slogan;
