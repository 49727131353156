import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const CoursesSlider = () => {
	return (
		<Splide
			className='mt-4'
			options={{
				type: 'loop',
				perPage: 9,
				perMove: 2,
				focus: 'center',
				speed: 250,
				breakpoints: {
					800: { perPage: 1 },
					1150: { perPage: 2 },
					1500: { perPage: 3 },
					1850: { perPage: 4 },
					2200: { perPage: 5 },
					2550: { perPage: 6 },
					2900: { perPage: 7 },
					3250: { perPage: 8 },
				},
			}}
		>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
			<SplideSlide>
				<img src='https://picsum.photos/360/210' />
			</SplideSlide>
		</Splide>
	);
};

export default CoursesSlider;
