import React from 'react';
import { useHistory } from 'react-router-dom';

import eBook from 'assets/ebook.pdf';
import mockup from 'assets/book_mockup.png';

import 'assets/styles/reward.css';

const DownloadBook = () => {
	const history = useHistory();

	if (!localStorage.getItem('subscribed')) history.push('/signup');

	return (
		<div className='gift-container'>
			<div className='book-description'>
				<h5>
					<b>
						Ви благодариме што сте дел од нашата мејлинг листа. Како знак на
						благодарност, добивате поклон! 🎉🎉🎉
					</b>
				</h5>
				Дизајниран водич кој ќе ја отклучи вашата генијалност, ќе ги активира
				вашите потенцијали и разбуди вашата имагинација
			</div>
			<img src={mockup} className='book-mockup' />
			<a
				href={eBook}
				download='e-kniga.pdf'
				className='btn btn-primary download-btn'
			>
				Download
			</a>
		</div>
	);
};

export default DownloadBook;
