import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import loadingGIF from 'assets/img/loading.gif';

import 'assets/styles/signup.css';

const Input = ({ name, label, doChange, type = 'text', placeholder }) => {
	return (
		<label htmlFor={name} className='form-label'>
			{label}
			<input
				type={type}
				id={name}
				name={name}
				className='form-control'
				onChange={doChange}
				placeholder={placeholder}
			/>
		</label>
	);
};

const formUrl =
	'https://docs.google.com/forms/d/e/1FAIpQLSekG1MlcIGMl0mEAU0bbRTFCeLeS6sG1sA3U_ebA6Trk9oPfg/formResponse';

const SignupForm = ({ history }) => {
	const [inputs, setInputs] = useState({
		name: { id: 1863726479, value: '' },
		email: { id: 2024991989, value: '' },
	});

	const [loading, setLoading] = useState(false);
	// false - not submitted, true - loading, 'done' - finished loading, 'error' - bad response status

	const [error, setError] = useState('');

	const doSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);

		const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

		if (!emailRegex.test(inputs.email.value.trim())) {
			setLoading('error');
			setError('Email address invalid');
			return;
		}

		const formData = new FormData();

		Object.entries(inputs).map(([key, item]) => {
			formData.append(`entry.${item.id}`, item.value);
		});

		try {
			const res = await fetch(formUrl, {
				method: 'post',
				body: formData,
				mode: 'no-cors',
			});
		} catch (error) {
			setLoading('error');
			setError('An error occured while sending your request');
			console.error("There's been an error", error);
		}

		setLoading('done');

		localStorage.setItem('subscribed', true);
		history.push('/freeBook');
		history.go();
	};

	const handleChange = (e) => {
		setLoading(false);
		setError('');

		const { value, name } = e.target;

		inputs[name].value = value;

		setInputs(inputs);
	};

	return (
		<form onSubmit={doSubmit} className='signup-form'>
			<div className='form-group'>
				<Input
					name='name'
					label='Name'
					doChange={handleChange}
					placeholder='Name'
				/>
			</div>
			<div className='form-group'>
				<Input
					name='email'
					label='Email'
					doChange={handleChange}
					type='email'
					placeholder='email@example.com'
				/>
			</div>
			<div className='form-group'>
				<button type='submit' className='btn btn-warning'>
					Sign Up
				</button>
				<span className='loadingState'>
					{loading === true ? (
						<img src={loadingGIF} className='loadingGIF' />
					) : loading === 'done' ? (
						<i className='fas fa-check-circle text-success'></i>
					) : loading === 'error' ? (
						<i className='fas fa-times text-danger'></i>
					) : (
						''
					)}
				</span>
			</div>
			<span className='errorMsg'>{error}</span>
		</form>
	);
};

export default withRouter(SignupForm);
