import React from 'react';

const HowTo = () => {
	return (
		<div className='row steps-row'>
			<div className='howto col-lg-4 col-md-4 col-sm-12 col-xs-12'>
				<div className='howto__number'>
					<h2>1</h2>
				</div>
				<img
					src='https://picsum.photos/370/270'
					alt=''
					className='howto__image'
				/>
				<span className='howto__text'>Регистрирај се за бесплатно</span>
			</div>
			<div className='howto col-lg-4 col-md-4 col-sm-12 col-xs-12'>
				<div className='howto__number'>
					<h2>2</h2>
				</div>
				<img
					src='https://picsum.photos/370/270'
					alt=''
					className='howto__image'
				/>
				<span className='howto__text'>Данлодирај ја нашата апликација</span>
			</div>
			<div className='howto col-lg-4 col-md-4 col-sm-12 col-xs-12'>
				<div className='howto__number'>
					<h2>3</h2>
				</div>
				<img
					src='https://picsum.photos/370/270'
					alt=''
					className='howto__image'
				/>
				<span className='howto__text'>Најди ги твоите најомилени тренинзи</span>
			</div>
		</div>
	);
};

export default HowTo;
