import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Header from 'components/layout/CoverHeader';

import { getPageCategories } from 'services/firestoreUtils';

import 'assets/styles/article.css';

const DynamicPage = ({
	match: {
		params: { pageId },
	},
}) => {
	const [pageData, setPageData] = useState({});

	useEffect(async () => {
		try {
			const docData = await getPageCategories(pageId);
			setPageData(docData);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const splideOptions = {
		perPage: 9,
		perMove: 2,
		focus: 'center',
		breakpoints: {
			800: { perPage: 1 },
			1150: { perPage: 2 },
			1500: { perPage: 3 },
			1850: { perPage: 4 },
			2200: { perPage: 5 },
			2550: { perPage: 6 },
			2900: { perPage: 7 },
			3250: { perPage: 8 },
		},
		fixedHeight: '210px',
	};

	return (
		Object.keys(pageData).length && (
			<>
				<Header
					cover={`/img/page_covers/${pageId}.png`}
					title={pageData.header.title}
					description={pageData.header.description}
				/>
				<div className='articles-hr-bar'>Артикли</div>
				<main className='main-container'>
					{pageData.categories.map(({ title, articles }, sectionIndex) => (
						<div className='article-row' key={sectionIndex}>
							<h1 className='row-title'>{title}</h1>
							<Splide options={splideOptions}>
								{articles.map((articleId, articleIndex) => (
									<SplideSlide key={articleIndex}>
										<Link to={`/articles/${pageId}/${articleId}`}>
											<img src={`/img/thumbnails/${pageId}/${articleId}.png`} />
										</Link>
									</SplideSlide>
								))}
							</Splide>
						</div>
					))}
				</main>
			</>
		)
	);
};

export default DynamicPage;
