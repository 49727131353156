import React, { useState, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';

import Header from 'components/layout/CoverHeader';
import Sidebar from './Sidebar';

import { getArticleContent } from 'services/firestoreUtils';

import 'assets/styles/article.css';

const sanitize = (html) => {
	const clean = sanitizeHtml(html, {
		allowedAttributes: {
			a: ['href'],
		},
	});

	return clean;
};

const DynamicArticle = ({
	match: {
		params: { pageId, articleId },
	},
}) => {
	const [articleData, setArticleData] = useState(null);

	useEffect(async () => {
		try {
			const {
				content,
				description,
				title,
				author: { name: authorName, id: authorId },
			} = await getArticleContent(pageId, articleId);

			setArticleData({
				title,
				description,
				coverUrl: `/img/covers/${pageId}/${articleId}.png`,
				content: sanitize(content),
				authorName,
				authorId,
			});
		} catch (error) {
			console.error(error);
		}
	}, []);

	return (
		<>
			{articleData ? (
				<Header
					cover={articleData.coverUrl}
					title={articleData.title}
					description={articleData.description}
					rightCover
				/>
			) : (
				<header>
					<div className='header-cover'></div>
				</header>
			)}
			<div className='articles-hr-bar'>Article</div>
			<main className='main-article-container'>
				{articleData ? (
					<div
						className='text-container'
						dangerouslySetInnerHTML={{ __html: articleData.content }}
					></div>
				) : (
					<div className='text-container'></div>
				)}
				<Sidebar />
			</main>
		</>
	);
};

export default DynamicArticle;
