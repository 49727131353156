import React from 'react';

const Header = (props) => {
	return (
		<header className={props.rightCover ? 'right-cover' : ''}>
			<div className='header-cover'>
				{props.cover && props.cover.length ? <img src={props.cover} /> : null}
				<div className='gradient'></div>
			</div>
			<div className='header-details'>
				<h1 className='header-title'>{props.title}</h1>
				<span className='header-description'>{props.description}</span>
			</div>
		</header>
	);
};

export default Header;
