import React, { Fragment } from 'react';

import GeniusSlider from './GeniusSlider';
import Slogan from './Slogan';
import CoursesSlider from './CoursesSlider';
import HowTo from './HowTo';
import Testimonials from './Testimonials';

import 'assets/styles/landing.css';

const Landing = () => {
	return (
		<Fragment>
			<section className='slogan-section'>
				<Slogan />
				<GeniusSlider />
			</section>
			<section className='courses-section p-3 pt-4 pb-5'>
				<h1>Најнови и популарни курсеви</h1>
				<CoursesSlider />
			</section>
			<section className='howto-section'>
				<h1 className='mb-5'>Супер! Како работи?</h1>
				<HowTo />
			</section>
			<section className='testimonial-section container'>
				<h1 className='testimonial-section__heading'>
					Генијалните умови сакаат најдобри
				</h1>
				<Testimonials />
			</section>
		</Fragment>
	);
};

export default Landing;
