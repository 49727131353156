import React from 'react';
import { Link } from 'react-router-dom';

import CourseDropdown from './CourseDropdown';

import 'assets/styles/navbar.css';

const Navbar = () => {
	const dropDown = () => {
		const dropdownCategories = document.querySelector('#categories');

		const displayBlock = dropdownCategories.style.display == 'block';
		dropdownCategories.style.display = displayBlock ? 'none' : 'block';

		const dropArrowClasses = document.querySelector('#dropdown-arrow')
			.classList;
		if ([...dropArrowClasses].includes('fa-bars')) {
			dropArrowClasses.remove('fa-bars');
			dropArrowClasses.add('fa-minus');
		} else {
			dropArrowClasses.remove('fa-minus');
			dropArrowClasses.add('fa-bars');
		}
	};

	return (
		<div className='nav-container'>
			<nav id='top-navbar' className='navbar nav-colored can-transparent'>
				<a className='navbar-brand' href='/'>
					<div className='logo__column1'>
						<span id='mali' className='text-white'>
							МАЛИ И
						</span>
						<span id='vozrasni' className='text-white'>
							ВОЗРАСНИ
						</span>
					</div>
					<span id='genijalci' className='text-darker-warning'>
						ГЕНИЈАЛЦИ
					</span>
				</a>
				<Link to='/' className='btn text-light ml-auto'>
					<i className='fas fa-home fa-2x'></i>
					<div>Home</div>
				</Link>
				<Link to='/signup' className='btn text-light'>
					<i className='far fa-envelope fa-2x'></i>
					<div>Subscribe</div>
				</Link>
				<div className='dropdown course-dropdown'>
					<button
						className='btn text-light'
						type='button'
						id='dropdownMenuButton'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						<i className='fas fa-th fa-2x'></i>
						<div>
							Categories&nbsp;&nbsp;<i className='fas fa-caret-down'></i>
						</div>
					</button>
					<CourseDropdown />
				</div>
				<form action='/search' id='search-form' className='form-inline'>
					<input
						className='form-control'
						type='search'
						placeholder='Search'
						aria-label='Search'
						name='query'
					/>
					<button className='btn' type='submit' id='search-button'>
						<i className='fas fa-search text-white'></i>
					</button>
				</form>
				<button className='btn ml-1' onClick={() => dropDown()}>
					<i className='text-light fa-3x fas fa-bars' id='dropdown-arrow'></i>
				</button>
			</nav>
			<div id='categories'>
				<a href='/articles/start_here'>
					<h5>Почнете овде</h5>
				</a>
				<a href='#'>
					<h5>Најдобрите Тренинзи</h5>
				</a>
				<a href='#'>
					<h5>Тестирања и кариерни советувања</h5>
				</a>
				<a href='#'>
					<h5>Tренинзи за родители</h5>
				</a>
				<a href='#'>
					<h5>Видеа</h5>
				</a>
				<a href='#'>
					<h5>Аудио книги</h5>
				</a>
				<a href='#'>
					<h5>Блогови</h5>
				</a>
				<a href='#'>
					<h5>Контактирајте не</h5>
				</a>
			</div>
		</div>
	);
};

export default Navbar;
