import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import einstein from 'assets/img/einstein.png';
import maxwell from 'assets/img/maxwell.png';
import tesla from 'assets/img/tesla.png';
import curie from 'assets/img/curie.png';

const GeniusSlider = () => {
	return (
		<div className='col2'>
			<Splide
				options={{
					type: 'fade',
					rewind: true,
					pagination: false,
					arrows: false,
					autoplay: true,
					interval: 3000,
				}}
			>
				<SplideSlide>
					<img src={einstein} />
				</SplideSlide>
				<SplideSlide>
					<img src={maxwell} />
				</SplideSlide>
				<SplideSlide>
					<img src={tesla} />
				</SplideSlide>
				<SplideSlide>
					<img src={curie} />
				</SplideSlide>
			</Splide>
		</div>
	);
};

export default GeniusSlider;
