import React, { Fragment } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Navbar from 'components/layout/Navbar';
import Landing from 'components/landing/Landing';
import DynamicArticle from 'components/articles/DynamicArticle';
import DynamicPage from 'components/articles/DynamicPage';
import Signup from 'components/forms/SignupPage';
import DownloadBook from 'components/DownloadBook';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import 'assets/styles/style.css';

const App = () => {
	const history = createBrowserHistory();

	return (
		<>
			<Router history={history}>
				<Navbar />
				<Route exact path='/' component={Landing} />
				<Route exact path='/signup' component={Signup} />
				<Route exact path='/freeBook' component={DownloadBook} />
				<Route
					exact
					path='/articles/:pageId/:articleId'
					component={DynamicArticle}
				/>
				<Route exact path='/articles/:pageId' component={DynamicPage} />
			</Router>
		</>
	);
};

export default App;
