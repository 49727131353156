import React from 'react';

const Testimonials = () => {
	return (
		<div className='row'>
			<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
				<p className='testimonial'>
					<i className='fas fa-quote-left'></i> Лорем ипсум долор сит амет, еи
					мел суас сцрипторем, лабитур цонтентионес иус ид. Цум пурто детрахит
					цу, мелиоре алияуандо ассентиор но меи. Нихил фиерент садипсцинг хас
					ид, цум яуидам волутпат аргументум ан. Сеа цу долорум мелиоре. Поссе
					фацер дефинитионес ад хис, луптатум ассентиор репудиандае мел еу, меи
					не долор оцурререт."
					<span>Име Презиме</span>
				</p>

				<p className='testimonial'>
					<i className='fas fa-quote-left'></i> Лорем ипсум долор сит амет, еи
					мел суас сцрипторем, лабитур цонтентионес иус ид. Цум пурто детрахит
					цу, мелиоре алияуандо ассентиор но меи. Нихил фиерент садипсцинг хас
					ид, цум яуидам волутпат аргумен"
					<span>Име Презиме</span>
				</p>
			</div>
			<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
				<p className='testimonial'>
					<i className='fas fa-quote-left'></i> Лорем ипсум долор сит амет, еи
					мел суас сцрипторем, лабитур цонтентионес иус ид. Цум пурто детрахит
					цу, мелиоре алияуандо ассентиор но меи. Нихил фиерент садипсцинг хас
					ид, цум яуидам волутпат аргумен"
					<span>Име Презиме</span>
				</p>

				<p className='testimonial'>
					<i className='fas fa-quote-left'></i> Лорем ипсум долор сит амет, еи
					мел суас сцрипторем, лабитур цонтентионес иус ид. Цум пурто детрахит
					цу, мелиоре алияуандо ассентиор но меи. Нихил фиерент садипсцинг хас
					ид, цум яуидам волутпат аргументум ан. Сеа цу долорум мелиоре. Поссе
					фацер дефинитионес ад хис, луптатум ассентиор репудиандае мел еу, меи
					не долор оцурререт."
					<span>Име Презиме</span>
				</p>
			</div>
			<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
				<p className='testimonial'>
					<i className='fas fa-quote-left'></i> Лорем ипсум долор сит амет, еи
					мел суас сцрипторем, лабитур цонтентионес иус ид. Цум пурто детрахит
					цу, мелиоре алияуандо ассентиор но меи. Нихил фиерент садипсцинг хас
					ид, цум яуидам волутпат аргументум ан. Сеа цу долорум мелиоре. Поссе
					фацер дефинитионес ад хис, луптатум ассентиор репудиандае мел еу, меи
					не долор оцурререт."
					<span>Име Презиме</span>
				</p>

				<p className='testimonial'>
					<i className='fas fa-quote-left'></i> Лорем ипсум долор сит амет, еи
					мел суас сцрипторем, лабитур цонтентионес иус ид. Цум пурто детрахит
					цу, мелиоре алияуандо ассентиор но меи. Нихил фиерент садипсцинг хас
					ид, цум яуидам волутпат аргумен"
					<span>Име Презиме</span>
				</p>
			</div>
		</div>
	);
};

export default Testimonials;
