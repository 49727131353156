import firebase from 'firebase';

firebase.initializeApp({
	projectId: 'mali-vozrasni-genijalci',
});

const db = firebase.firestore();
db.enablePersistence();

export { firebase, db };
