import React from 'react';

import 'assets/styles/course_dropdown.css';

const CourseDropdown = () => {
	return (
		<>
			<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-brain'></i> Емоционална Интелигенција
				</a>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-book'></i> Подготовка за студии во странство
				</a>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-calculator'></i> Математика
				</a>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-laptop-code'></i> ИТ
				</a>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-atom'></i> Физика и астрономија
				</a>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-chess'></i> Шах
				</a>
				<a href='#' className='dropdown-item'>
					<i className='fas fa-flask'></i> Хемија
				</a>
			</div>
		</>
	);
};

export default CourseDropdown;
